// ** Auth Endpoints
export default {
  loginEndpoint: "/api/auth/login",
  adminLogin: "/api/auth/login",
  // user sign-up endpoint
  registerEndpoint: "/api/user-signup",
  refreshEndpoint: "/api/auth/refresh-token",
  logoutEndpoint: "/jwt/logout",
  reCaptchaEndpoint: "/api/google/recaptcha/v3",
  // ** This will be prefixed in authorization header with token
  // ? e.g. Authorization: Bearer <token>
  tokenType: "Bearer",

  // ** Value of this property will be used as key to store JWT token in storage
  storageTokenKeyName: "accessToken",
  storageRefreshTokenKeyName: "refreshToken",
};
