// ** React Imports
// import { Fragment } from "react";

// ** Custom Components
import NavbarUser from "./NavbarUser";
import NavbarBookmarks from "./NavbarBookmarks";

const ThemeNavbar = (props) => {
  // ** Props
  const { skin, setSkin, setMenuVisibility } = props;

  return (
    <div className="w-100 d-flex ">
      <div className="bookmark-wrapper d-flex align-items-center">
        <NavbarBookmarks setMenuVisibility={setMenuVisibility} />
      </div>
      <NavbarUser skin={skin} setSkin={setSkin} />
    </div>
  );
};

export default ThemeNavbar;
