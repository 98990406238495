// ** Dropdowns Imports

import UserDropdown from "./UserDropdown";
import NavbarSearch from "./NavbarSearch";
import { Badge } from "reactstrap";
// ** Utils
// import { getUserData } from "@utils";
import { useSelector } from "react-redux";
import { numberWithCommas } from "../../../../common/functions/helperFunctions";

// ** Third Party Components
// import { Sun, Moon } from "react-feather";

// ** Reactstrap Imports
// import { NavItem, NavLink } from "reactstrap";

const NavbarUser = () => {
  // const user = getUserData();

  const currentUser = useSelector((state) => state.currentUser);
  // ** Props
  // const { skin, setSkin } = props;

  // ** Function to toggle Theme (Light/Dark)
  // const ThemeToggler = () => {
  //   if (skin === "dark") {
  //     return <Sun className="ficon" onClick={() => setSkin("light")} />;
  //   } else {
  //     return <Moon className="ficon" onClick={() => setSkin("dark")} />;
  //   }
  // };

  return (
    <ul className="align-items-center d-flex justify-content-end justify-content-lg-between nav navbar-nav w-100">
      {/* <IntlDropdown /> */}
      {/* <NavItem className="d-none d-lg-block">
        <NavLink className="nav-link-style">
          <ThemeToggler />
        </NavLink>
      </NavItem> */}
      <NavbarSearch />
      {/* <CartDropdown /> */}
      <div className="d-flex">
        {currentUser?.currentuserdata?.data?.user_subscription_id && (
          <div className="d-flex align-items-center header-carats-balance pe-25">
            <h6 className="mb-0 me-auto font-medium-1">Dollar Balance</h6>
            <Badge color="light-primary mx-25 p-50 font-medium-2" pill>
              {numberWithCommas(
                currentUser?.currentuserdata?.data?.total_carets
              ) || 0}
            </Badge>
          </div>
        )}

        <div className="d-flex align-items-center header-carats-balance beta-text-header">
          <span className="p-50 text-white fw-bolder px-1">BETA</span>
        </div>

        <UserDropdown />
      </div>
    </ul>
  );
};
export default NavbarUser;
