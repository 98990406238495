/* eslint-disable multiline-ternary */
// ** React Imports
import { Link, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import classnames from "classnames";

// ** Custom Components
import Avatar from "@components/avatar";

// ** Utils
import { isUserLoggedIn } from "@utils";

// ** Store & Actions
import { useDispatch, useSelector } from "react-redux";
import { handleLogout } from "@store/authentication";

// ** Third Party Components
import { User, Power } from "react-feather";

// ** Reactstrap Imports
import {
  UncontrolledDropdown,
  DropdownMenu,
  DropdownToggle,
  DropdownItem,
} from "reactstrap";
import {
  ADMIN_ROUTES,
  AUTH_ROUTES,
  USER_ROUTES,
} from "../../../../constant/route";
import { ROLE } from "../../../../constant/constant";

// ** Default Avatar Image
// import defaultAvatar from "@src/assets/images/portrait/small/avatar-s-11.jpg";

const UserDropdown = () => {
  const currentUser = useSelector((state) => state.currentUser);

  const navigate = useNavigate();
  // ** Store Vars
  const dispatch = useDispatch();

  // ** State
  const [userData, setUserData] = useState(null);

  //** ComponentDidMount
  useEffect(() => {
    if (isUserLoggedIn() !== null) {
      setUserData(JSON.parse(localStorage.getItem("userData")));
    }
  }, []);

  //** Vars process.env.REACT_APP_BASENAME
  const userAvatar = currentUser?.currentuserdata?.data?.user_image;

  const LogoutFunction = () => {
    dispatch(handleLogout());
    dispatch({ type: "RESET_STORE" });
    // if (userData?.role === ROLE.USER) {
    window.open("/", "_self");
    // }
    // if (
    //   [ROLE.SUPER_ADMIN, ROLE.ADMIN, ROLE.CUSTOMER_SUPPORT].includes(
    //     userData?.role
    //   )
    // ) {
    //   window.open("/admin/login", "_self");
    // }
  };
  // const Name = `${currentUser?.currentuserdata?.data?.first_name} ${currentUser?.currentuserdata?.data?.last_name}`;

  // console.log(userData);
  return (
    <UncontrolledDropdown tag="li" className="dropdown-user nav-item">
      <DropdownToggle
        href="/"
        tag="a"
        className="nav-link dropdown-user-link m-0"
        onClick={(e) => e.preventDefault()}
      >
        {/* <div className="user-nav d-sm-flex d-none">
          <span className="user-name fw-bold">
            {(userData && userData["first_name"]) || "user"}
          </span>
          <span className="user-status">
            {(userData && userData.role) || ""}
          </span>
        </div> */}

        {userAvatar ? (
          <Avatar
            img={userAvatar}
            imgHeight="40"
            imgWidth="40"
            content={currentUser?.currentuserdata?.data?.first_name || ""}
            initials
          />
        ) : (
          <Avatar
            style={{ padding: "2px" }}
            color="light-primary"
            content={currentUser?.currentuserdata?.data?.first_name || ""}
            initials
          />
        )}
        {/* <Avatar
          style={{ padding: "2px" }}
          color="light-primary"
          content={currentUser?.currentuserdata?.data?.first_name || ""}
          initials
        /> */}
      </DropdownToggle>
      <DropdownMenu end>
        <DropdownItem
          className={classnames("w-100", {
            // "pointer-events-none": userData?.role === ROLE.SUPER_ADMIN,
          })}
          onClick={() => {
            if (
              [
                ROLE.SUPER_ADMIN,
                ROLE.ADMIN,
                ROLE.CUSTOMER_SUPPORT,
                ROLE.EDITOR,
              ].includes(userData.role)
            ) {
              navigate(ADMIN_ROUTES.PROFILE);
            } else {
              navigate(USER_ROUTES.USER_PROFILE);
            }
          }}
        >
          <User size={14} className="me-75" />
          <span className="user-name fw-bold">
            {currentUser?.currentuserdata?.data?.first_name
              ? currentUser?.currentuserdata?.data?.first_name
              : "user"}
          </span>
        </DropdownItem>
        <DropdownItem
          className="w-100"
          // tag={Link}
          // to={
          //   userData?.role === "admin"
          //     ? ADMIN_ROUTES.APP_LOGIN
          //     : AUTH_ROUTES.APP_LOGIN
          // }
          onClick={LogoutFunction}
        >
          <Power size={14} className="me-75" />
          <span className="align-middle">Logout</span>
        </DropdownItem>
      </DropdownMenu>
    </UncontrolledDropdown>
  );
};

export default UserDropdown;
