import { USER_ROUTES } from "../../constant/route";
import mock from "../mock";

export const searchArr = [
  {
    groupTitle: "Pages",
    searchLimit: 4,
    data: [
      {
        id: 130,
        icon: "DollarSign",
        isBookmarked: false,
        target: "pricing",
        link: USER_ROUTES.PRICING,
        title: "Pricing",
      },
      {
        id: 130,
        icon: "FolderPlus",
        isBookmarked: false,
        target: "myprojects",
        link: USER_ROUTES.MY_PROJECTS,
        title: "My Orders",
      },
      {
        id: 131,
        icon: "User",
        isBookmarked: false,
        target: "userprofile",
        link: "/apps/user-profile",
        title: "User Profile",
      },
      {
        id: 132,
        icon: "FileText",
        isBookmarked: false,
        target: "coinstatement",
        link: USER_ROUTES.CARATS_STATEMENT,
        title: "Coins Statement",
      },
      {
        id: 134,
        icon: "DollarSign",
        isBookmarked: false,
        target: "subscription",
        link: USER_ROUTES.SUBSCRIPTION,
        title: "Subscription Management",
      },
    ],
  },
];

// GET Search Data
mock.onGet("/api/main-search/data").reply(() => {
  return [200, { searchArr }];
});

// GET Search Data & Bookmarks
mock.onGet("/api/bookmarks/data").reply(() => {
  const bookmarks = searchArr[0].data.filter((item) => item.isBookmarked);
  const suggestions = searchArr[0].data;
  return [200, { suggestions, bookmarks }];
});

// POST Update isBookmarked
mock.onPost("/api/bookmarks/update").reply((config) => {
  const { id } = JSON.parse(config.data);

  const obj = searchArr[0].data.find((item) => item.id === id);

  Object.assign(obj, { isBookmarked: !obj.isBookmarked });

  return [200];
});
